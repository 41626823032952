.sponsor-card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 80px 0;
}
.sponsor-card-root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sponsor-image {
  width: 200px;
  padding: 15px;
}