.header {
  width: 100%;
  background: #211c21;
}
.header-top-side {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.header-logo-text {
  margin-left: 20px;
  color: #f2f2f2;
}
.header-logo-text p,
.header-logo-text h2 {
  margin: 0;
  font-family: "Roboto mono", monospace;
  font-style: normal;
}
.header-logo-text p {
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
}
.header-logo-text h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}
.header-right-side {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.header-button {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
}

.header-button .register {
  height: 100px;
  width: 180px;
  margin: auto;
  background-color: #d53b65;
  border: none;
  cursor: pointer;
  color: #f2f2f2;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-family: "Roboto mono", monospace;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  outline: none;
}
.header-button .mobile-view {
  border-radius: 0;
  margin-top: 20px;
}
