.need-help-section-container {
  margin: 200px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.need-help-title {
  font-size: 36px;
}
.need-help-description {
  width: 60%;
  margin: 20px 0 30px;
  text-align: center;
}
.need-help-description p {
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .need-help-section-container {
    margin: 100px 0;
  }
  .need-help-title {
    font-size: 24px;
  }
  .need-help-description {
    width: 100%;
    margin: 10px 0 15px;
  }
}
