.agenda-card {
  width: 100%;
  min-height: 120px;
  background: #141414;
  position: relative;
  border: 0.5px solid #141414;
  box-sizing: border-box;
  display: flex;
  padding-top: 40px;
  padding-left: 50px;
}

.agenda-card.expanded {
  transform: translateX(-10px);
  box-shadow: 10px -10px #63509b;
}

.agenda-card:hover {
  border: 0.5px solid #d53b65;
  box-sizing: border-box;
}

.agenda-card-event-content-container {
  width: 80%;
}
.agende-card-time-zones-container {
  width: 20%;
}
.event-card-title-container {
  display: flex;
  justify-content: space-between;
}
.agenda-card .expand-icon-container .expand-icon {
  color: #f2f2f2;
  width: 12px;
  margin-right: 20px;
  cursor: pointer;
}

.agenda-card .speakers-container {
  display: flex;
  flex-wrap: wrap;
}

.agenda-card .event-details {
  margin-top: 20px;
}

.location-container {
  display: flex;
  align-items: center;
}
.location-container .pin {
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .agenda-card {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
  .agende-card-time-zones-container {
    width: 100%;
    align-items: flex-start;
  }
  .agenda-card-event-content-container {
    padding-top: 0;
    width: 100%;
  }
}
