.content-box-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content-box-root p {
  width: 80%;
}
@media only screen and (max-width: 720px) {
  .content-box-root p {
    width: 100%;
  }
}
