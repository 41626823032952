.abt-container {
  margin: 0 0 100px;
}

.text-box2{
  margin-bottom: 50px;
}
.register-btn {
  top: 330px;
  margin-bottom: 70px;
}
.abt-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.abt-youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
