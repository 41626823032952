.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hero button {
  margin-top: 50px;
}
.hero-section-button-container,
.hero-section-content {
  width: 60%;
}
.hero-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.hero button:hover {
  color: #f2f2f2;
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .hero {
    height: auto;
    margin: 100px 0;
  }
}
@media only screen and (max-width: 450px) {
  .hero {
    align-items: flex-start;
  }
  .hero button {
    margin-left: 0px;
  }
  .hero-section-button-container,
  .hero-section-content {
    width: 100%;
  }
}
