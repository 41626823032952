.faq-questions-section-root {
  width: 100%;
}
.faq-questions-container {
  display: flex;
}
.faq-categories-container {
  width: 200px;
  background: black;
  margin-right: 50px;
  border: 1px solid #d53b65;
  border-radius: 4px;
}
.faq-category {
  padding: 8px 30px;
  text-align: center;
  border-bottom: 1px solid #d53b65;
  cursor: pointer;
  text-transform: capitalize;
}
.faq-category:last-child {
  border-bottom: none;
}
.faq-category.active {
  background: #d53b65;
}
.faq-questions-right-section {
  width: 100%;
}
.load-more-button-container {
  display: inline-block;
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .faq-questions-container {
    flex-direction: column;
  }
  .faq-category {
    border-bottom: 0;
    border: 1px solid #d53b65;
  }
  .faq-categories-container {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin-right: 0;
    margin: 0 auto 50px;
  }
  .faq-questions-right-section {
    width: 80%;
    margin: auto;
  }
}

@media only screen and (max-width: 500px) {
  .faq-questions-right-section {
    width: 95%;
    margin: auto;
  }
}
