.footercard {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-left {
  width: 60%;
  min-height: 400px;
}
.footer-right {
  margin-left: 100px;
  width: 30%;
  min-height: 400px;
}
.footer-left-header {
  font-family: "Roboto mono", monospace;
  font-size: 36px;
  color: #f2f2f2;
}
.footer-left-paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #b2a6c9;
  margin: 20px 0;
}
.footer-left-form {
  display: none;
  width: 100%;
  height: 70px;
  border: 1px solid #da98a910;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}
.footer-left-input {
  font-size: 16px;
  color: #f2f2f2;
  width: 100%;
  height: 60px;
  padding-left: 10px;
  background: none;
  border: none;
  outline: none;
}
.footer-left-form Button {
  margin: 20px;
  margin-top: 25px;
}
.footer-left-input::placeholder {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #f2f2f2;
}
.footer-right-header {
  font-family: "Roboto mono", monospace;
  font-size: 36px;
  color: #f2f2f2;
  margin: 30px 0;
}
.footer-right-paragraph,
.footer-right-paragraph > a:link,
.footer-right-paragraph > a:visited {
  text-decoration: none;
  font-family: "Poppins";
  font-size: 16px;
  margin: 20px 0;
  cursor: pointer;
  color: #b2a6c9;
}
.footer-right-icons {
  display: inline-block;
  margin-right: 10px;
}
@media (max-width: 950px) {
  .footercard {
    flex-direction: column;
  }
  .footer-right {
    margin: 0;
  }
  .footer-left,
  .footer-right {
    width: 100%;
    min-height: auto;
  }
}
