.days {
  height: 50px;
  color: #f2f2f2;
  display: flex;
  box-sizing: border-box;
  margin-bottom: 0.5px;
}

.days .tab.right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.days .tab.left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.days .tab.typography {
  box-sizing: border-box;
  color: #f2f2f2;
  margin: 0;
  padding: 0;
  height: 50px;
  width: 450px;
  border: 0.5px solid #63509b;

  background: #141414;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.days .tab.typography.selected {
  background: #63509b;
  cursor: default;
  pointer-events: none;
  /* width: 448px; */
}
@media only screen and (max-width: 960px) {
  .days {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .days .tab.typography {
    width: 100%;
  }
}
