.translation-language-container.desktop-position {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.translation-language-container.mobile-position {
  position: static;
  display: flex;
  align-items: center;
}
.translation-dropdown-container > div {
  border: 1px solid #b2a6c9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px 10px;
}
.translation-dropdown-options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.translation-dropdown-container svg {
  color: #b2a6c9;
}
