.particles {
  position: relative;
  z-index: -1;
}

.particles .particle {
  position: absolute;
  z-index: -1;
}

.particles .particle-1 {
  top: 110px;
  right: 0;
}

.particles .particle-2 {
  top: 752px;
  left: -30px;
}

.particles .particle-3 {
  top: 920px;
  right: 125px;
}

.particles .particle-4 {
  top: -30px;
  left: 361px;
  z-index: 1200;
}

.particles .particle-5 {
  top: 1475px;
  right: 0;
}

.particles .particle-6 {
  top: 1700px;
  left: -20px;
}

.particles .particle-7 {
  top: 2150px;
  left: -30px;
}

.particles .particle-8 {
  top: 2250px;
  right: -30px;
}

.particles .particle-9 {
  top: 4840px;
  right: 10px;
}

.particles .particle-10 {
  top: 3900px;
  left: -30px;
}

.particles .particle-11 {
  top: 3200px;
  right: 30px;
}

.particles .particle-12 {
  top: 3300px;
  left: -30px;
}

.particles .particle-13 {
  top: 1940px;
  right: 452px;
}

.particles .particle-14 {
  top: 3540px;
  right: -30px;
}

.particles .particle-15 {
  top: 4840px;
  left: -50px;
}

.particles .particle-16 {
  top: 4140px;
  right: -30px;
}

.particles .particle-17 {
  top: 4140px;
  right: 300px;
}

.particles .particle-18 {
  top: 5330px;
  left: -30px;
}

.particles .particle-19 {
  top: 5330px;
  right: -30px;
}

.particles .particle-20 {
  top: 6245px;
  left: -30px;
}

.particles .particle-21 {
  top: 6285px;
  right: -30px;
}

.particles .particle-22 {
  top: 6925px;
  right: -30px;
}

.particles .particle-23 {
  top: 6965px;
  left: -30px;
}

.particles .particle-24 {
  top: 7600px;
  left: -30px;
}

.particles .particle-25 {
  top: 7950px;
  right: -30px;
}

.particles .particle-26 {
  top: 8235px;
  left: -30px;
}

.particles .particle-27 {
  top: 7460px;
  right: -30px;
}

.particles .particle-28 {
  top: 8260px;
  right: 70px;
}

@media only screen and (max-width: 960px) {
  .particles {
    display: none;
  }
}
