body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #211c21;
  overflow-x: hidden;
}
.sections-positioning {
  max-width: 900px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  z-index: 1;
}
@media only screen and (max-width: 960px) {
  body {
    padding: 0 20px;
  }
}
