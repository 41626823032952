.modal-card-root {
  width: 300px;
  height: 100px;
  position: fixed;
  right: 0%;
  top: 30%;
  transform: translate(0%, -50%);
  background-color: #d53b65;
  animation-name: modalCardOpenning;
  animation-duration: 1s;
  animation-timing-function: linear;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.modal-card-root .modal-icons-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.modal-card-root .modal-icons-container button {
  outline: none;
}
.modal-card-facebook-icon {
  width: 24px;
}
.modal-card-linkedin-icon {
  width: 32px;
}
.close-button::before {
  position: absolute;
  content: "x";
  color: #da98a9;
  font-weight: 700;
  font-family: Roboto Mono, sans-serif;
  font-size: 24px;
  top: 0;
  right: 25px;
  cursor: pointer;
}
@keyframes modalCardOpenning {
  0% {
    right: -100%;
    opacity: 0;
  }

  100% {
    right: 0%;
    opacity: 1;
  }
}

@media only screen and (max-width: 720px) {
  .modal-card-root {
    height: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
