.ticket-card-grid {
  border: 1px solid #d53b65;
  border-radius: 20px;
  padding: 75px 0;
}

.ticket-section-container {
  max-width: 1470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ticket-card-container {
  display: flex;
  width: 80%;
  height: 300px;
  margin: 20px auto 20px;
  background-color: #63509b;
}

.ticket-card-empty-side {
  width: 50px;
  background-color: #63509b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ticket-card-empty-block {
  background: #211c21;
  width: 20px;
  height: 18px;
}

.ticket-card-empty-block.block-left-1 {
  clip-path: ellipse(18px 18px at 10% 0%);
}

.ticket-card-empty-block.block-left-2 {
  clip-path: ellipse(18px 18px at 10% 50%);
  height: 36px;
}

.ticket-card-empty-block.block-left-3 {
  clip-path: ellipse(18px 18px at 10% 100%);
}

.ticket-card-empty-block.block-right-1 {
  clip-path: ellipse(18px 18px at 100% 0%);
  margin-left: auto;
}

.ticket-card-empty-block.block-right-2 {
  clip-path: ellipse(18px 18px at 100% 50%);
  height: 36px;
  margin-left: auto;
}

.ticket-card-empty-block.block-right-3 {
  clip-path: ellipse(18px 18px at 100% 100%);
  margin-left: auto;
}

.section-button-container {
  position: relative;
  margin: auto;
  margin-bottom: 225px;
  top: -25px;
}

.ticket-card-data-section {
  background-color: #ee8936;
  width: 100%;
  display: flex;
}

.ticket-card-data-section-left {
  width: 60%;
  height: 100%;
  border-right: 5px dotted black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ticket-card-location-only-section {
  display: flex;
  align-items: center;
}

.ticket-card-data-section-right {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.ticket-card-name-section,
.ticket-card-location-section,
.ticket-card-date-section {
  padding: 0px 20px 0px 20px;
}

.ticket-card-firstname {
  line-height: 8px;
}

.ticket-card-lastname {
  line-height: 16px;
}

.ticket-card-location-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticket-card-time-section {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.label-icon {
  margin-right: 10px;
}

.ticket-card-date-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.ticket-card-month-section {
  width: 60%;
  margin: auto;
  text-align: center;
  background-color: #d53b65;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-card-date1-section,
.ticket-card-date2-section {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  margin: auto;
  text-align: center;
}

.ticket-card-date1-section {
  background-color: #b661b5;
}

.ticket-card-date2-section {
  background-color: #63509b;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ticket-card-admit {
  text-transform: uppercase;
  line-height: 8px;
}

.ticket-card-price {
  line-height: 16px;
  margin: 30px auto 20px auto;
}

@media only screen and (max-width: 960px) {
  .ticket-card-container {
    width: 85%;
    height: 275px;
  }
  .default-title.card-sub-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .ticket-card-container {
    width: 90%;
    height: 250px;
  }

  .ticket-card-date-section {
    height: 50px;
  }

  .ticket-card-price {
    margin: 20px auto 15px auto;
  }

  .ticket-card-time-section {
    margin-left: 30px;
  }

  .ticket-card-name-section,
  .ticket-card-location-section,
  .ticket-card-date-section {
    padding: 0px 5px 0px 5px;
  }
}

@media only screen and (max-width: 650px) {
  .ticket-card-location-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .ticket-card-time-section {
    margin-left: 0px;
  }

  .ticket-card-location,
  .ticket-card-time {
    line-height: 8px;
  }

  .ticket-card-location-only-section,
  .ticket-card-time-section {
    height: 30px;
  }
  .ticket-card-empty-side {
    width: 20px;
  }
  .ticket-card-title.card-sub-title {
    font-size: 12px;
  }
  .ticket-card-container .ticket-card-month-section h1,
  .ticket-card-container .ticket-card-date1-section h1,
  .ticket-card-container .ticket-card-date2-section h1 {
    font-size: 10px;
  }
  .ticket-card-grid {
    padding: 40px 0;
  }
  .ticket-card-data-section-right {
    padding: 0 2px;
  }
}