.participant-card-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.role-card {
  text-align: center;
  margin-bottom: 200px;
}
@media only screen and (max-width: 720px) {
  .participant-card-root {
    justify-content: center;
    flex-direction: column;
  }
  .role-card {
    margin-bottom: 50px;
  }
}
