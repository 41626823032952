.prize-images-root {
  margin-bottom: 200px;
}
.prize-images-root a p {
  margin: 0;
  margin-top: 25px;
}
.prize-images-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (max-width: 860px) {
  .prize-images-container {
    flex-direction: column;
    align-items: center;
  }
  .prize-images-container img:first-child {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 450px) {
  .free-training-img,
  .discount-img {
    width: 80%;
  }
}
