.faq-card-root {
  width: 100%;
  border-bottom: 0.5px solid #d53b65;
  margin-top: 25px;
  padding-bottom: 25px;
}
.faq-card-root:first-child {
  margin-top: 0;
}
.faq-card-root:last-child {
  border-bottom: none;
}
.card-question-container {
  display: flex;
  align-items: flex-start;
  padding: 0;
}
.faq-card-arrow-button {
  cursor: pointer;
  margin-left: 20px;
}
.faq-card-bullet {
  color: #f2f2f2;
}
