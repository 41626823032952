.profile-card-container {
  width: 250px;
  height: 360px;
  border-radius: 5px;
}

.profile-card-title-section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-linkedin-link {
  cursor: pointer;
}

.profiles-section-container {
  max-width: 1470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.profile-cards-container {
  grid-gap: 60px;
  position: relative;
}

.profile-card-description-wrapper {
  background: #63509b;
  padding: 15px;
  position: absolute;
  width: 220px;
  height: 220px;
  top: 0;
  opacity: 0;
  transition: all 0.3s ease-out;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.profile-card-description-wrapper::-webkit-scrollbar {
  display: none;
}

.profile-card-image-container {
  position: relative;
}
.profile-card-image {
  width: 250px;
  height: 250px;
}

.profile-card-container:hover .profile-card-description-wrapper {
  opacity: 1;
}

.profile-card-description {
  transition: all 0.3s ease-out;
  transform: translateY(15px);
}

.profile-card-container:hover .profile-card-description {
  transform: translateY(0px);
}
