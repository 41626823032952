.social {
  position: fixed;
  top: 45%;
  right: 20px;
}
.footer-icons {
  bottom: 0;
  left: 50%;
  display: flex;
}
.footer-icons .social-media-icon {
  margin: 50px 20px 0;
}
.social .social-media-icon {
  margin: 20px 0;
}
.social-media-icon {
  cursor: pointer;
}
.social-media-icon img {
  width: 15px;
  height: 15px;
}
