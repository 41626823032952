body {
  background: #201c20;
}
.main-button-container {
  width: 140px;
  height: 50px;
  background: #d53b65;
  border-radius: 2px;
  padding: 0;
  box-shadow: #63509b 6px -6px 0 0px;
  border: none;
}
.main-button-container.large {
  width: 240px;
}
.main-button-container.purple-bg {
  background: #b661b5;
  box-shadow: #63509b 6px -6px 0 0px;
}
.main-button-container:focus {
  outline: none;
}
.main-button-container:hover {
  transition: all 0.3s;
  transform: translate(6px, -6px);
  box-shadow: none;
}
.main-button-container a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
