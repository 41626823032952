.agenda {
  width: 900px;
}

.agenda .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agenda .local-time-zone {
  display: flex;
  align-items: flex-end;
}

.agenda .local-time-zone .toggle-switch {
  margin: 78px 20px 0 20px;
}

.agenda .title.typography {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #f2f2f2;
  margin-bottom: 30px;
  max-width: 600px;
  display: inline-block;
}

.agenda .header .local-time-zone .local-time-zone-text {
  display: inline-block;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #f2f2f2;
}

.agenda .agenda-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agenda .load-more {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 30px;
  margin-top: 30px;
  width: max-content;
  height: 16px;
  margin-left: 417px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #da98a9;
  cursor: pointer;
  box-sizing: border-box;
}

.agenda .load-more:hover {
  border-bottom: 0.5px solid #da98a9;
}

.agenda .load-more.hidden:hover {
  opacity: 0;
}
.date-buttons-container {
  display: flex;
}
@media only screen and (max-width: 960px) {
  .agenda {
    width: 100%;
  }
  .agenda .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .agenda .local-time-zone {
    margin: 50px 0;
  }
  .agenda .local-time-zone .toggle-switch {
    margin: 0 20px;
  }
}
@media only screen and (max-width: 960px) {
  .agenda .load-more {
    margin-left: 0;
  }
}

@media only screen and (max-width: 960px) {
  .local-time-zone-text {
    left: 0;
  }
}
