.left-navbar {
  position: fixed;
  top: 35%;
  left: 20px;
}
.header-navbar ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}

.header-navbar ul li {
  margin: 0 10px;
}
.left-navbar ul li {
  margin: 20px 0;
}
.mobile-navbar ul li a {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.mobile-navbar ul li a {
  height: 30px;
}
.navbar ul li a {
  text-decoration: none;
  color: #da98a9;
  font-family: "Roboto mono", monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}
.navbar ul li a:hover,
.navbar ul li a.active-nav-link {
  color: #f2f2f2;
}
.burger-menu-container {
  margin-right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
}
.burger-menu {
  position: absolute;
  top: 12px;
  width: 30px;
  height: 5px;
  background-color: #f2f2f2;
}
.burger-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  width: 30px;
  height: 5px;
  background-color: #f2f2f2;
}
.burger-menu::after {
  content: "";
  position: absolute;
  top: 10px;
  width: 30px;
  height: 5px;
  background-color: #f2f2f2;
}
