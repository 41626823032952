.default-title,
.default-paragraph.register-button {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: #f2f2f2;
}
.default-title.hero-title {
  font-size: 72px;
}
.default-title.section-title {
  font-size: 54px;
}
.default-title.card-sub-title {
  font-size: 25px;
  line-height: 8px;
}
.default-title.card-details {
  font-size: 12px;
  font-weight: 300;
}
.default-paragraph {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #b2a6c9;
}
.default-paragraph.white {
  color: #fff;
}
.default-paragraph.hero-paragraph {
  font-weight: 300;
  font-size: 18px;
}
.default-paragraph.section-paragraph {
  font-weight: 400;
  font-size: 16px;
}
.default-paragraph.card-name {
  font-weight: 400;
  font-size: 18px;
}
.default-paragraph.register-button {
  font-size: 14px;
  font-family: "Roboto Mono", sans-serif;
}
.default-paragraph.card-title {
  font-size: 14px;
  line-height: 16px;
}
.default-paragraph.card-description {
  font-size: 12px;
  line-height: 12px;
}
.default-paragraph.card-description {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.default-paragraph.pink-button {
  font-family: "Roboto Mono", sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #da98a9;
}
.typography-link {
  display: inline-block;
  text-decoration: none;
}
@media only screen and (max-width: 720px) {
  .default-title.hero-title {
    font-size: 48px;
  }
  .default-title.section-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 400px) {
  .default-title.hero-title {
    font-size: 32px;
  }
  .default-title.section-title {
    font-size: 20px;
  }
}
